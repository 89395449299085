import * as React from "react"

import Seo from "../components/Seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Seite nicht gefunden." />
    <h1>404: Seite nicht gefunden.</h1>
    <p>Diese Seite existiert nicht.</p>
  </>
)

export default NotFoundPage
